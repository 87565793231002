/* eslint-disable react/require-default-props */
import {
    Project,
    SupplierConfig
} from '@nexploretechnology/concreting-core-client/concrete/project.management-client/project.management.dto';
import { Form } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import useApi from 'src/app-react/hooks/useApi';
import { SupplierConfigForm } from './components/Form';
import { SupplierConfigModal } from './components/Modal';
import { SupplierConfigFormContext } from './contexts/SupplierConfigFormContext';

interface SupplierConfigFormProps {
    projectId?: string;
    companyId: string;
    hideModal: () => void;
    showModal: boolean;
}

function SupplierConfigModalForm({ companyId, projectId, hideModal, showModal }: SupplierConfigFormProps) {
    const [form] = Form.useForm();
    const [marketId, setMarketId] = useState<string>('DE');
    const [supplierConfig, setSupplierConfig] = useState<SupplierConfig>({} as SupplierConfig);
    const api = useApi();

    const supplierConfigFormContextValue = useMemo(
        () => ({
            supplierConfig,
            marketId
        }),
        [supplierConfig, marketId]
    );

    function createSupplierConfig(fieldsValue: SupplierConfig) {
        if (!projectId) {
            return;
        }

        api.projectManagementClient
            .createSupplierConfig({ ...fieldsValue, projectId }, companyId, projectId)
            .then(hideModal)
            .catch((error) => {
                ErrorNotification({ message: error.code, description: error.message });
            });
    }

    function updateSupplierConfig(fieldsValue: SupplierConfig) {
        if (!projectId) {
            return;
        }

        api.projectManagementClient
            .updateSupplierConfig({ ...fieldsValue, id: supplierConfig.id, projectId }, companyId, projectId)
            .then(hideModal)
            .catch((error) => {
                ErrorNotification({ message: error.code, description: error.message });
            });
    }

    async function getSupplierConfigData() {
        if (!projectId) {
            return;
        }

        api.projectManagementClient
            .getSupplierConfig(companyId, projectId)
            .then((response) => {
                const data: SupplierConfig = response.getEntity();
                setSupplierConfig(data);
            })
            .catch((error) => {
                if (error?.message?.match(/404/)) {
                    setSupplierConfig({} as SupplierConfig);
                } else {
                    ErrorNotification({ message: error.code, description: error.message });
                }
            });
    }

    async function getMarketIdFromProject() {
        if (!projectId) {
            return;
        }

        api.projectManagementClient
            .getProject(companyId, projectId)
            .then((response) => {
                const data: Project = response.getEntity();

                if (data?.marketId) {
                    setMarketId(data.marketId);
                }
            })
            .catch((error) => {
                ErrorNotification({ message: error.code, description: error.message });
            });
    }

    function cleanForm() {
        form.setFieldsValue({
            supplierSoftware: undefined,
            supplierName: undefined,
            url: '',
            supplierProjectId: '',
            contractId: '',
            dispgroup: '',
            customerId: '',
            username: '',
            password: '',
            subscriptionKey: '',
            appCode: '',
            useSupplierUI: undefined,
            isApiType: undefined
        });
    }

    const onCancelModal = () => {
        form.resetFields();
        cleanForm();
        hideModal();
    };

    const onFinish = (fieldsValue: SupplierConfig & { integrationType: string }) => {
        form.validateFields()
            .then(() => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { integrationType, ...inputSupplierConfig } = {
                    ...fieldsValue,
                    isApiType: fieldsValue.integrationType === 'isApiType',
                    isManualType: fieldsValue.integrationType === 'isManualType',
                    isQrType: fieldsValue.integrationType === 'isQrType',
                    isOcrType: false
                };

                if (supplierConfig?.id) {
                    updateSupplierConfig(inputSupplierConfig);
                } else {
                    createSupplierConfig(inputSupplierConfig);
                }

                form.resetFields();
            })
            .catch((e) => {
                ErrorNotification({ message: e.code, description: e.message });
            });
    };

    useEffect(() => {
        if (showModal && supplierConfig?.id) {
            form.setFieldsValue({
                supplierSoftware: supplierConfig?.supplierSoftware,
                supplierName: supplierConfig?.supplierName,
                url: supplierConfig?.url,
                supplierProjectId: supplierConfig?.supplierProjectId,
                contractId: supplierConfig?.contractId,
                dispgroup: supplierConfig?.dispgroup,
                customerId: supplierConfig?.customerId,
                username: supplierConfig?.username,
                password: supplierConfig?.password,
                subscriptionKey: supplierConfig?.subscriptionKey,
                appCode: supplierConfig?.appCode,
                bcqdToSupplierToken: supplierConfig?.bcqdToSupplierToken,
                isApiType: supplierConfig?.isApiType,
                useSupplierUI: supplierConfig?.useSupplierUI
            });
        }
    }, [supplierConfig, form, showModal]);

    useEffect(() => {
        if (showModal) {
            getSupplierConfigData();
            getMarketIdFromProject();
        }
    }, [projectId, showModal, marketId]);

    useEffect(() => {
        if (showModal) {
            cleanForm();
        }
    }, [projectId]);

    return (
        <SupplierConfigFormContext.Provider value={supplierConfigFormContextValue}>
            <SupplierConfigModal open={showModal} onCancel={onCancelModal} onOk={form.submit}>
                <SupplierConfigForm form={form} onFinish={onFinish} initialValues={supplierConfig} />
            </SupplierConfigModal>
        </SupplierConfigFormContext.Provider>
    );
}

export default SupplierConfigModalForm;
