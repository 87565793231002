import { MARKET_VALUES } from '../markets';
import { IMarketData } from '../marketsData';
import { ORDER_STATUS_VALUES } from '../orderStatus';
import { SUPPLIERS, SUPPLIER_VALUES } from '../suppliers';
import { BERGER_DATA } from './berger';
import { CEMEX_DATA } from './cemexData';
import { CIMIC_DATA } from './cimic';
import { CONCRETE_DIRECT_DATA } from './concreteDirectData';
import { CONCRETE_DIRECT_DATA_V2 } from './concreteDirectV2Data';
import { DYCKERHOFF_DATA } from './dyckerhoff';
import { GODEL_DATA } from './godelData';
import { SAUTER_DSA_DATA } from './sauterDsaData';
import { SIMMA_DATA } from './simaData';
import { TRAPOBET_DATA } from './trapobet';

export interface ISupplierDataStatusConfig {
    allow?: ORDER_STATUS_VALUES[];
    disallow?: ORDER_STATUS_VALUES[];
}

export interface ISupplierData {
    market: MARKET_VALUES;
    label: string;
    name: string[];
    apiIntegration?: boolean;
    qrIntegration?: boolean;
    manualIntegration?: boolean;
    softwareName?: string;

    permissions: {
        allowOrderEdit: boolean;
        allowOrderCancel: boolean;
        allowOrderAdditionalQuantity: boolean;
        allowGenerateDocumentationOrders: boolean;
        allowGenerateDocumentationPerConcreteType: boolean;
    };

    custom?: IMarketData['custom'] & {
        orderEditStatus?: ISupplierDataStatusConfig;
        orderCancelStatus?: ISupplierDataStatusConfig;
        orderAdditionalQuantityStatus?: ISupplierDataStatusConfig;
        checkDeliveryTimeFunc?: (deliveryTime?: Date) => boolean;
    };

    constants?: IMarketData['constants'];
}

export const SUPPLIERS_DATA: Record<SUPPLIER_VALUES, ISupplierData> = {
    [SUPPLIERS.SIMMA]: SIMMA_DATA,
    [SUPPLIERS.CEMEX_GO]: CEMEX_DATA,
    [SUPPLIERS.CONCRETE_DIRECT]: CONCRETE_DIRECT_DATA,
    [SUPPLIERS.CONCRETE_DIRECT_V2]: CONCRETE_DIRECT_DATA_V2,
    [SUPPLIERS.DYCKERHOFF]: DYCKERHOFF_DATA,
    [SUPPLIERS.GODEL]: GODEL_DATA,
    [SUPPLIERS.SAUTER]: SAUTER_DSA_DATA,
    [SUPPLIERS.CIMIC]: CIMIC_DATA,
    [SUPPLIERS.BERGER]: BERGER_DATA,
    [SUPPLIERS.TRAPOBET]: TRAPOBET_DATA
};
