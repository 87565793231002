import { EditOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Trans } from '@lingui/macro';
import {
    DeliveryOverviewStatus,
    OrderDeliveryNotes
} from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { OrderOverviewBP } from '@nexploretechnology/concreting-core-client/concrete/order.monitoring-client/order.monitoring.dto';
import { Button, Table } from 'antd';
import { NumberFormatter } from 'src/app-react/components/Formatters';
import DateFormatter from 'src/app-react/components/Formatters/DateFormatter';
import TimeFormatter from 'src/app-react/components/Formatters/TimeFormatter';
import { MARKETS } from 'src/app-react/constants/markets';
import { ModalState } from 'src/app-react/hooks/useModalState';
import useOrderPermissions from 'src/app-react/hooks/useOrderPermissions';
import useProjectState from 'src/app-react/hooks/useProjectState';
import useUserPermissions from 'src/app-react/hooks/useUserPermissions';
import OrderLiteLoadModal from '../../../../shared/Modals/OrderLiteModal';
import { keyMapFromTableData } from '../util';
import { StatusOverview } from './StatusOverview';

const supplierConcreteDirect = 'holcim.concrete.direct';
const supplierConcreteDirectV2 = 'holcim.concrete.direct.v2';
const supplierCemex = 'cemex.cemexgo';

interface Props {
    infoOverview: OrderDeliveryNotes;
    percent: DeliveryOverviewStatus;
    orderOverview: OrderOverviewBP;
    setVisibleAdditionalQuantityModal: React.Dispatch<React.SetStateAction<boolean>>;
    statusOverview: DeliveryOverviewStatus;
    orderLiteModalState: ModalState;
    orderLiteModalQRState: ModalState;
    editOrder: () => void;
}

export function InfoOverview({
    infoOverview,
    percent,
    orderOverview,
    setVisibleAdditionalQuantityModal,
    statusOverview,
    orderLiteModalState,
    orderLiteModalQRState,
    editOrder
}: Props): JSX.Element {
    const getOrderPermissions = useOrderPermissions();
    const { supplierId, marketId, projectPermissions } = useProjectState();
    const permissions = useUserPermissions();

    if (!infoOverview || !percent) return null;

    return (
        <>
            <Table
                key="infoOverview"
                scroll={{ x: 1000 }}
                pagination={false}
                dataSource={keyMapFromTableData([infoOverview])}
                columns={[
                    {
                        title: <Trans>Structural element</Trans>,
                        key: 'structuralElement',
                        dataIndex: 'structuralElement',
                        width: 250,
                        render: () => <span key="structuralElement">{infoOverview.structuralElement}</span>
                    },
                    {
                        title: marketId === MARKETS.DE ? <Trans>Concrete type</Trans> : <Trans>Mix design</Trans>,
                        dataIndex: 'concreteTypeNumber',
                        width: 100,
                        render: () => orderOverview?.orderedConcreteType?.concreteTypeNumber || ''
                    },
                    {
                        title: <Trans>Order number</Trans>,
                        dataIndex: 'orderNumber',
                        render: () => (
                            <>
                                {infoOverview.orderNumber} <br />
                                <br />
                            </>
                        )
                    },
                    {
                        title: <Trans>Delivery time</Trans>,
                        dataIndex: 'deliveryDate',
                        render: () => (
                            <>
                                <DateFormatter date={infoOverview.DeliveryDateAndTime} />
                                <br />
                                <TimeFormatter date={infoOverview.DeliveryDateAndTime} />
                            </>
                        )
                    },
                    {
                        title:
                            marketId === MARKETS.DE ? (
                                <Trans>Quantity / Output</Trans>
                            ) : (
                                <Trans>Quantity / Spacing</Trans>
                            ),
                        dataIndex: 'quantity',
                        render: () => {
                            return marketId === MARKETS.DE ? (
                                <>
                                    <NumberFormatter value={infoOverview.quantity} unit={infoOverview.unitQuantity} />
                                    <br />
                                    <NumberFormatter value={infoOverview.output} unit={infoOverview.unitOutput} />
                                </>
                            ) : (
                                <>
                                    <NumberFormatter value={infoOverview.quantity} unit={infoOverview.unitQuantity} />
                                    <br />
                                    <NumberFormatter value={infoOverview.spacingInMinutes} unit="min" />
                                </>
                            );
                        }
                    },
                    {
                        title: <Trans>Unloading Location</Trans>,
                        dataIndex: 'unloadingLocation',
                        render: () => (
                            <>
                                {infoOverview.unloadingLocation}
                                <br />
                                <br />
                            </>
                        )
                    },
                    {
                        title: <Trans>Actions</Trans>,
                        dataIndex: 'actions',
                        render: () => {
                            if (!permissions.isForeman || !orderOverview) {
                                return null;
                            }

                            const { allowEdit, allowAdditionalQuantity } = getOrderPermissions({
                                record: orderOverview
                            });

                            return (
                                <>
                                    <Button
                                        hidden={
                                            supplierId === supplierConcreteDirect ||
                                            supplierId === supplierConcreteDirectV2
                                        }
                                        data-testid="editOrder"
                                        key="editOrder"
                                        type="link"
                                        size="small"
                                        disabled={!allowEdit}
                                        onClick={editOrder}>
                                        <EditOutlined /> <Trans>Edit</Trans>
                                    </Button>
                                    <Button
                                        hidden={supplierId === supplierCemex}
                                        data-testid="openAdditionalQuantityModal"
                                        key="openAdditionalQuantityModal"
                                        type="link"
                                        disabled={!allowAdditionalQuantity}
                                        size="small"
                                        onClick={() => setVisibleAdditionalQuantityModal(true)}>
                                        <PlusSquareOutlined /> <Trans>Additional quantity</Trans>
                                    </Button>
                                    <Button
                                        type="link"
                                        size="small"
                                        data-testid="order-lite-modal-add-btn"
                                        hidden={!projectPermissions.allowCreateOrderLoadLite}
                                        onClick={orderLiteModalState.open}>
                                        <PlusSquareOutlined /> <Trans>Create delivery ticket</Trans>
                                    </Button>
                                    <Button
                                        type="link"
                                        size="small"
                                        data-testid="concrete-load-lite-qr-add-btn"
                                        hidden={!projectPermissions.allowCreateOrderLoadLiteQR}
                                        onClick={orderLiteModalQRState.open}>
                                        <PlusSquareOutlined /> <Trans>Create delivery ticket (QR)</Trans>
                                    </Button>
                                </>
                            );
                        }
                    }
                ]}
            />
            <Table
                key="statusOverView"
                showHeader={false}
                scroll={{ x: 1000 }}
                pagination={false}
                dataSource={keyMapFromTableData([infoOverview])}
                columns={[
                    {
                        dataIndex: 'statusOverViewColumn',
                        render: () => <StatusOverview statusOverview={statusOverview} percent={percent} />
                    }
                ]}
            />

            {orderLiteModalState.isOpen && (
                <OrderLiteLoadModal
                    open={orderLiteModalState.isOpen}
                    close={orderLiteModalState.close}
                    orderDeliveryNotes={infoOverview}
                />
            )}
            {orderLiteModalQRState.isOpen && (
                <OrderLiteLoadModal
                    type="Qr"
                    open={orderLiteModalQRState.isOpen}
                    close={orderLiteModalQRState.close}
                    orderDeliveryNotes={infoOverview}
                />
            )}
        </>
    );
}
